import React from 'react';
import '../Home/styles.css';

import privacyHtml from './privacy';

function PrivacyPolicy(props) {
    document.title = 'Política de Privacidade | wcr.design';

    return (
        <div>
            <div className="container-page">
                <header>
                    <nav className="topbar">
                        <a href='./'>
                            <img className="logo" src="img/logo.png" alt="Logo da empresa" />
                        </a>
                        <div className="topbar-menus">
                            <a href="https://instagram.com/wcr.design/"><button><img src="img/icons/brand-instagram.svg" alt="Icone do Instagram" /></button></a>
                            <a href="https://www.facebook.com/profile.php?id=100065221701811"><button><img src="img/icons/brand-facebook.svg" alt="Icone do Facebook" /></button></a>
                            <a href="https://www.linkedin.com/company/wcrdesign/"><button><img src="img/icons/brand-linkedin.svg" alt="Icone do Linkedin" /></button></a>
                            <a href="./briefing"><button><img src="img/icons/sparkles.svg" alt="Icone de brilho" />Comece um projeto</button></a>
                        </div>
                    </nav>
                </header>
                <section className="body">
                    <div className='content' style={{maxWidth: '800px'}}>
                        <div dangerouslySetInnerHTML={{__html: privacyHtml}} className='privacy'></div>
                    </div>
                </section>
                <footer>
                    <div className='content'>
                        <p>© 2023 wcr.design - CNPJ: 37.328.207/0001-08 - Todos os direitos reservados</p>
                        <div className="links">
                            <a href="tel:47936188359"><p>(47) 93618-8359</p></a>
                            <a href="./terms-of-use"><p>Termos de uso</p></a>
                            <a href="./privacy-policy"><p>Política de privacidade</p></a>
                        </div>
                    </div>
                </footer>
            </div>
            <div className="background">
            </div>
            <div className="bg-glow">
                <img src="img/bg.svg" alt="Background Glow" className="bg" />
            </div>
        </div>
    );
}

export default PrivacyPolicy;