import React, { useEffect, useRef, useState } from "react";
import './styles.css'

import Line from "./Line";
import projects from "./projects.json";

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper';
import 'swiper/swiper-bundle.min.css';

SwiperCore.use([Autoplay]);

function Home() {
    document.title = 'wcr.design | Design e desenvolvimento de produtos digitais';

    const [indexImageSwiper, setIndexImageSwiper] = useState(0);

    const imageSwiperRef = useRef(null);

    const nextSlide = (e) => {
        imageSwiperRef.current.swiper.slideNext();
    }

    const previousSlide = (e) => {
        imageSwiperRef.current.swiper.slidePrev();
    }

    return (
        <div>
            <div className="container-page">
                <header>
                    <nav className="topbar">
                        <img className="logo" src="img/logo.png" alt="Logo da empresa" />
                            <div className="topbar-menus">
                                <a href="https://instagram.com/wcr.design/"><button className="wui button secondary social-media"><img src="img/icons/brand-instagram.svg" alt="Icone do Instagram" /></button></a>
                                <a href="https://www.facebook.com/profile.php?id=100065221701811"><button className="wui button secondary social-media"><img src="img/icons/brand-facebook.svg" alt="Icone do Facebook" /></button></a>
                                <a href="https://www.linkedin.com/company/wcrdesign/"><button className="wui button secondary social-media"><img src="img/icons/brand-linkedin.svg" alt="Icone do Linkedin" /></button></a>
                                <a href="./briefing"><button className="wui cta-button secondary" id="Header_CTA"><img src="img/icons/sparkles.svg" alt="Icone de brilho" />Comece um projeto</button></a>
                            </div>
                    </nav>
                </header>
                <section className="hero">
                    <div className="container-content-hero">
                        <div className="info">
                            <h1>Unimos <span>design</span> e <span>desenvolvimento</span> para criar <span className="highlight">produtos digitais</span> inovadores e eficientes.</h1>
                            <a href="https://wa.me/message/A4DPXVMVBH62H1" target="_blank" rel="noopener noreferrer"><button className="wui cta-button primary" id="Hero_CTA"><img src="img/icons/message-dots.svg" alt="Icone de brilho" />Fale com a gente</button></a>
                        </div>
                        <div>
                            <div className="container-swiper">  
                                <Swiper
                                    slidesPerView={1}
                                    spaceBetween= {10}
                                    slideActiveClass="active"
                                    className="swiper"
                                    speed={400}
                                    onSlideChange={() => {
                                        setIndexImageSwiper(imageSwiperRef.current.swiper.activeIndex)
                                    }}
                                    autoplay={
                                        {
                                            delay: 5000,
                                            disableOnInteraction: false,
                                            waitForTransition: false
                                        }
                                    }
                                    centeredSlides={true}
                                    effect="slide"
                                    ref={imageSwiperRef}
                                >
                                    {
                                        projects.map((project, index) => (
                                          <SwiperSlide key={index}>
                                              <div className="swiper-slide__img">
                                                  <img src={project.image} alt={project.projectName}/>
                                              </div>
                                          </SwiperSlide>
                                        ))
                                    }
                                </Swiper>
                            </div>
                            <div className="footer-swiper">
                                <div id="tags">
                                    {
                                        projects.map((project, index) => (
                                            <div className="container-tags" key={index} style={{
                                                display: index === indexImageSwiper ? 'flex' : 'none'
                                            }}>
                                                <span className="tag">{project.projectType}</span>
                                                <span className="tag">{project.clientName}</span>
                                                <span className="tag">{project.url}</span>
                                            </div>
                                        ))
                                    }
                                </div>
                                <div className="buttons-prev-next">
                                    <button id="custom-prev-button" onClick={previousSlide}><img src="img/icons/chevron-left.svg" alt="Retroceder Icone" /></button>
                                    <button id="custom-next-button" onClick={nextSlide}><img src="img/icons/chevron-right.svg" alt="Avançar Icone" /></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <footer>
                    <div className='content'>
                        <p>© 2023 wcr.design - CNPJ: 37.328.207/0001-08 - Todos os direitos reservados</p>
                        <div className="links">
                            <a href="tel:47936188359"><p>(47) 93618-8359</p></a>
                            <a href="./terms-of-use"><p>Termos de uso</p></a>
                            <a href="./privacy-policy"><p>Política de privacidade</p></a>
                        </div>
                    </div>
                </footer>
            </div>
            <div className="background">
                <Line
                    className="lottie-right"
                    direction={-1}
                    height={'1007px'}
                    width={'860px'}
                />
                <Line
                    className="lottie-left"
                    height={'1007px'}
                    width={'860px'}
                />
            </div>
            <div className="bg-glow">
                <img src="img/bg.svg" alt="Background Glow" className="bg" />
            </div>
        </div>
    )
}

export default Home;