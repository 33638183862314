import React from "react";
import { Routes, Route } from 'react-router-dom';
import Briefing from "../Pages/Briefing";
import Home from "../Pages/Home";
import Portfolio from "../Pages/Portfolio";
import TermsOfUse from "../Pages/TermsOfUse";
import PrivacyPolicy from "../Pages/PrivacyPolicy";
import Page404 from "../Pages/404";

function RoutesList(){
    return (
        <Routes>
            <Route index element={<Home/>} />
            <Route path="/briefing" element={<Briefing/>} />
            <Route path="/portfolio" element={<Portfolio/>} />
            <Route path="/terms-of-use" element={<TermsOfUse/>} />
            <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
            <Route path='*' element={<Page404 />}/>
        </Routes>
    )
}

export default RoutesList;