import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';

import line from "./line.json";

class Line extends React.Component {
  constructor(props) {
    super(props);
    this.player = React.createRef(); // initialize your ref
  }
  changeDirection() {
    this.player.current.setPlayerDirection(this.props.direction ? this.props.direction : 1);
  }
  render() {
    return (
        <Player
            className={this.props.className}
            onEvent={event => {
            if (event === 'load') this.changeDirection(); // check event type and do something
            }}
            ref={this.player}
            autoplay={true}
            loop={true}
            controls={true}
            src={line}
            style={{ height: this.props.height ? this.props.height : '300px', width: this.props.width ? this.props.width : '300px' }}
        />
    );
  }
}

export default Line;